<template>
    <div>
		<CustomTable
			ref="table"
			id_table="acte_selection"
			:transformer="['ActeTransformer', 'heavy']"
			:busy.sync="table_busy"
			:hrefsRoutes="config_table_hrefs"
			primaryKey="actes_id"
			:base-filters="filters"
			:selectMode="unique ? 'single' : 'multi'"
        	:selected_ids.sync="local_selected"
		/>
    </div>
</template>

<script>
import Navigation from "@/mixins/Navigation.js"
import Shutter from "@/mixins/Shutter.js"

export default {
	name: 'ActSelection',
    mixins: [Navigation, Shutter],
    props: {
        selected: { type: Array, default: () => ([]) },
		unique: { type: Boolean, default: false }
    },
    data() {
        return {
            /* Table config : */
            table_busy: true,
			local_selected: [],
            config_table_hrefs: {
                'horse_nom': {
                    routeUniqueName: 'horseFiche',
                    routeName: 'horseFiche',
                    params: {
                        horse_id: 'actes_horse'
                    }
                }
            }
        }
	},
	computed: {
		filters() {
			return {
				is_validated: {
					column: 'actes_actesstatut',
					operator: 'isEqualTo',
					value: 0
				}
			}
		}
	},
    mounted() {
        this.init_component()
    },
    methods: {
		init_component() {
			this.local_selected = this.selected
		}
    },
	watch: {
		local_selected(val) {
			this.$emit('update:selected', val)
			
			if (val.length) {
				this.ok(val)
			}
		}
	},
    components: {
        CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
    }
}
</script>
